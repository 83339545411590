import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchQuestionnaires(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/questionnaires', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchQuestionnaire(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/questionnaires/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editQuestionnaire(ctx, {id, questionnaireData}) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/questionnaires/${id}`, questionnaireData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addQuestionnaire(ctx, questionnaireData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/questionnaires', questionnaireData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
