<template>

  <div>

    <!-- Filters -->
    <questionnaires-list-filters :type-filter.sync="typeFilter" :level-filter.sync="levelFilter"
      :department-filter.sync="departmentFilter" :type-options="typeOptions" :level-options="levelOptions"
      :department-options="departmentOptions" />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="limit" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
              <!-- <b-button variant="primary">
                <span class="text-nowrap">Add Questionnaire</span>
              </b-button> -->
              <b-dropdown v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="text-nowrap" right text="Add Questionnaire"
                variant="primary">
                <b-dropdown-item :to="{ name: 'questionnaire-create', params: { department: 'advisors' } }">
                  Advisors
                </b-dropdown-item>
                <b-dropdown-item :to="{ name: 'questionnaire-create', params: { department: 'implement' } }">
                  Implement
                </b-dropdown-item>
                <b-dropdown-item :to="{ name: 'questionnaire-create', params: { department: 'operations' } }">
                  Operations
                </b-dropdown-item>
                <b-dropdown-item :to="{ name: 'questionnaire-create', params: { department: 'partners' } }">
                  Partners
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table ref="refQuestionnaireListTable" class="position-relative" :items="fetchQuestionnaires" responsive
        :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
        :sort-desc.sync="sortOrderSync">

        <template #table-busy>
          <div class="text-center my-3">
            <b-spinner style="width: 3rem; height: 3rem;" variant="primary" label="Loading..."></b-spinner>
          </div>
        </template>
        <!-- Column: Title -->
        <template #cell(title)="data">
          <b-media vertical-align="center">
            <b-link :to="{ name: 'questionnaire-view', params: { id: data.item._id } }"
              class="font-weight-bold d-block text-nowrap lead">
              {{ data.item.title }}
            </b-link>
            <span class="text-secondary h5">{{ data.item.body.description }}</span>
          </b-media>
        </template>

        <!-- Column: Department -->
        <template #cell(department)="data">
          <div class="text-nowrap">
            <b-badge :variant="resolveDepartmentVariant(data.item.department)">
              <span class="text-capitalize">{{ data.item.department }}</span>
            </b-badge>
          </div>
        </template>

        <!-- Column: CreatedBy -->
        <template #cell(createdBy)="data">
          <b-media v-if="data.item.createdBy" vertical-align="center">
            <b-link class="font-weight-bold d-block text-nowrap ">
              {{ data.item.createdBy.fullName }}
            </b-link>
            <span class="text-secondary ">{{ data.item.createdBy.email }}</span>
          </b-media>
          <span v-else>N/A</span>
        </template>

        <!-- Column: Type -->
        <template #cell(type)="data">
          <span>
            <b-badge :variant="resolveQuestionnaireTypeVariant(data.item.type)">
              <feather-icon :icon="resolveQuestionnaireTypeIcon(data.item.type)" class="mr-25" />
              <span class="align-text-top text-capitalize"> {{ resolveQuestionnaireTypeText(data.item.type) }}</span>
            </b-badge>
          </span>
        </template>

        <!-- Column: Levels -->
        <template #cell(levels)="data">
          <b-media v-if="data.item.levels && data.item.levels.length" vertical-align="center">
            <span><b>{{ data.item.levels.map(level => level.name).join(', ') }}</b></span>
          </b-media>
          <b-badge v-else variant="light-danger" pill>Not Assigned</b-badge>
        </template>


        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-link v-b-tooltip.hover.top="'Edit'" :to="{ name: 'questionnaire-edit', params: { id: data.item._id } }">
            <feather-icon icon="EditIcon" class="edit-button ml-1" />
          </b-link>
          <b-link v-b-tooltip.hover.top="'Preview'" :to="{ name: 'questionnaire-view', params: { id: data.item._id } }">
            <feather-icon icon="FileTextIcon" class="details-button ml-50" />
          </b-link>
          <b-link v-b-tooltip.hover.top="'Duplicate'" @click="duplicateItem(data.item)">
            <feather-icon icon="LayersIcon" class="details-button ml-50 text-warning" />
          </b-link>
          <b-link v-b-tooltip.hover.top="'Delete'" @click="removeItem(data.item)">
            <feather-icon icon="Trash2Icon" class="details-button ml-50 text-danger" />
          </b-link>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
              entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination v-model="page" :total-rows="totalQuestionnaires" :per-page="limit" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BSpinner, VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import { ref, onUnmounted, computed, onMounted, getCurrentInstance } from '@vue/composition-api'
import QuestionnairesListFilters from './QuestionnairesListFilters.vue'
import useQuestionnairesList from './useQuestionnairesList'
import questionnaireStoreModule from '../questionnaireStoreModule'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    QuestionnairesListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  setup() {
    const QUESTIONNAIRE_APP_STORE_MODULE_NAME = 'app-questionnaire'
    const levelOptions = ref([])
    const typeOptions = [
      { label: 'Self Evaluation', value: 'selfEval' },
      { label: 'Peer Evaluation', value: 'eval360' },
      { label: 'End of Project Evaluation', value: 'projectEval' },
    ]
    const departmentOptions = [
      { label: 'Advisors', value: 'advisors' },
      { label: 'Implement', value: 'implement' },
      { label: 'Operations', value: 'operations' },
      { label: 'Partners', value: 'partners' },
      // { label: 'Program', value: 'program' },
    ]

    const item = ref(null)

    const { proxy } = getCurrentInstance()

    // Register module
    if (!store.hasModule(QUESTIONNAIRE_APP_STORE_MODULE_NAME)) store.registerModule(QUESTIONNAIRE_APP_STORE_MODULE_NAME, questionnaireStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(QUESTIONNAIRE_APP_STORE_MODULE_NAME)) store.unregisterModule(QUESTIONNAIRE_APP_STORE_MODULE_NAME)
    })

    // Fetch levels
    onMounted(() => {
      proxy.$http.get('/levels')
        .then(response => {
          const levels = response.data.levels
          levelOptions.value = levels.map(level => ({ label: level.name, value: level._id }))
        })
        .catch(error => console.error('Error fetching level options:', error))
    })

    const {
      fetchQuestionnaires,
      tableColumns,
      limit,
      page,
      totalQuestionnaires,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      sortOrder,
      refQuestionnaireListTable,
      refetchData,

      // UI
      resolveQuestionnaireTypeVariant,
      resolveQuestionnaireTypeIcon,
      resolveQuestionnaireTypeText,
      resolveDepartmentVariant,

      // Extra Filters
      typeFilter,
      levelFilter,
      departmentFilter,
    } = useQuestionnairesList()

    const sortOrderSync = computed({
      get: () => sortOrder.value === 'desc',
      set: (newVal) => {
        sortOrder.value = newVal ? 'desc' : 'asc'
      }
    })

    return {
      fetchQuestionnaires,
      tableColumns,
      limit,
      page,
      totalQuestionnaires,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      sortOrder,
      sortOrderSync,
      refQuestionnaireListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveQuestionnaireTypeVariant,
      resolveQuestionnaireTypeIcon,
      resolveQuestionnaireTypeText,
      resolveDepartmentVariant,
      resolveDepartmentVariant,

      typeOptions,
      levelOptions,
      departmentOptions,

      // Extra Filters
      typeFilter,
      levelFilter,
      departmentFilter,
      item
    }
  },
  methods: {
    // editItem(item) {
    //   this.item = item
    // },
    removeItem(item) {
      this.$bvModal
        .msgBoxConfirm(`Would you like to delete the questionnaire: "${item.title}" ?`, {
          title: '[Delete Questionnaire] Are you sure?',
          size: 'md',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$http.delete(`/questionnaires/${item._id}`)
              .then(response => {
                this.$refs.refQuestionnaireListTable.refresh()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Questionnaire deleted successfully!',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              })
              .catch(err => {
                const errorMessage = (err.response && err.response.data && err.response.data.error) || err || 'Could not delete the questionnaire.'
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    text: errorMessage,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    getDuplicate(item) {
      const duplicate = JSON.parse(JSON.stringify(item)) // Deep Copy
      const dateOptions = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        // timeZone: "UTC",
        timeZoneName: "short"
      }
      duplicate.levels = []
      duplicate.comments = `Duplicated on ${new Date().toLocaleDateString("en-US", dateOptions).split('at')[0]}`
      duplicate.title = `${duplicate.title} (Dupl.)`
      return duplicate
    },
    duplicateItem(item) {
      const duplicateItem = this.getDuplicate(item)
      const messageVNode = [
        this.$createElement('div', { domProps: { innerHTML: `<p>Would you like to duplicate the questionnaire: "${item.title}"?</p><small class="text-warning">*The duplicated questionnaire will have no assigned levels, please edit as necessary.</small>` } })
      ]
      this.$bvModal
        .msgBoxConfirm(messageVNode, {
          title: '[Duplicate Questionnaire] Are you sure?',
          size: 'lg',
          okVariant: 'warning',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$http.post('/questionnaires', duplicateItem)
              .then(response => {
                this.$refs.refQuestionnaireListTable.refresh()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Questionnaire duplicated successfully!',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              })
              .catch(err => {

                const errorMessage = (err.response && err.response.data && err.response.data.error) || err || 'Could not duplicate this questionnaire.'
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    text: errorMessage,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    }
  }
}
</script>


<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.status-dot--active {
  background-color: rgb(0, 165, 0);
}

.status-dot--inactive {
  background-color: rgba(255, 0, 0, 0.76);
}

.edit-button {
  color: $primary;
  cursor: pointer;
}

.details-button {
  color: $primary;
  cursor: pointer;
}
</style>
