import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useQuestionnairesList() {
  // Use toast
  const toast = useToast()

  const refQuestionnaireListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'title', sortable: true },
    { key: 'department', label: 'Dept.', sortable: true },
    { key: 'createdBy', sortable: true },
    { key: 'type', sortable: true },
    { key: 'levels', label: 'Levels', sortable: true },
    // { key: 'location', sortable: true },
    // { key: 'advisor', label: 'Advisor', sortable: true },
    // { key: 'isActive', label: 'Active', sortable: true },

    // {
    //   key: 'currentPlan',
    //   label: 'Plan',
    //   formatter: title,
    //   sortable: true,
    // },
    // { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const limit = ref(10)
  const totalQuestionnaires = ref(0)
  const page = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('updatedAt')
  const sortOrder = ref('desc')
  const typeFilter = ref(null)
  const levelFilter = ref(null)
  const departmentFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refQuestionnaireListTable.value ? refQuestionnaireListTable.value.localItems.length : 0
    return {
      from: limit.value * (page.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (page.value - 1) + localItemsCount,
      of: totalQuestionnaires.value,
    }
  })

  const refetchData = () => {
    refQuestionnaireListTable.value.refresh()
  }

  watch([page, limit, searchQuery, typeFilter, levelFilter, departmentFilter], () => {
    refetchData()
  })

  const fetchQuestionnaires = (ctx, callback) => {
    store
      .dispatch('app-questionnaire/fetchQuestionnaires', {
        q: searchQuery.value,
        limit: limit.value,
        page: page.value,
        sortBy: sortBy.value,
        sortOrder: sortOrder.value,
        type: typeFilter.value,
        level: levelFilter.value,
        department: departmentFilter.value ,
      })
      .then(response => {
        const { questionnaires, total } = response.data
        callback(questionnaires)
        totalQuestionnaires.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching questionnaires list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveQuestionnaireTypeVariant = type => {
    if (type === 'eval360') return 'light-warning'
    if (type === 'projectEval') return 'light-success'
    if (type === 'selfEval') return 'light-secondary'
    return 'FolderIcon'
  }

  const resolveQuestionnaireTypeIcon = type => {
    if (type === 'eval360') return 'DatabaseIcon'
    if (type === 'projectEval') return 'FolderIcon'
    if (type === 'selfEval') return 'SmileIcon'
    return 'FolderIcon'
  }

  const resolveQuestionnaireTypeText = type => {
    if (type === 'eval360') return 'Peer'
    if (type === 'projectEval') return 'End of Project'
    if (type === 'selfEval') return 'Self'
    return 'Evaluation'
  }

  const resolveDepartmentVariant = department => {
    if (department === 'advisors') return 'light-info'
    if (department === 'implement') return 'light-warning'
    if (department === 'operations') return 'light-secondary'
    if (department === 'partners') return 'light-primary'
    if (department === 'external') return 'light-danger'
    return 'primary'
  }

  return {
    fetchQuestionnaires,
    tableColumns,
    limit,
    page,
    totalQuestionnaires,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    sortOrder,
    refQuestionnaireListTable,

    resolveQuestionnaireTypeVariant,
    resolveQuestionnaireTypeIcon,
    resolveQuestionnaireTypeText,
    resolveDepartmentVariant,
    refetchData,

    // Extra Filters
    typeFilter,
    levelFilter,
    departmentFilter,
  }
}
